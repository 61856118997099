.payment-section {
    display: flex;
    justify-content: center;
    place-items: center;
    background: white;
    width: 100%;
    margin-top: 20px;
    border-radius: 5px;
    padding: 10px;
    min-height: 200px;
}

.success-message-container {
    display: flex;
    flex-direction: column;
    place-items: center;
}

.cancel-message-container {
    display: flex;
    place-items: center;
}

.success-check-icon {
    width: 50px;
    height: 50px;
}

.success-text {
    font-size: 13px;
    font-weight: 600;
    margin-left: 10px;
}

.sad-icon {
    width: 30px;
    height: 30px;
}

.wait-container {
    display: flex;
    place-items: center;
}

.success-text-and-img-container {
    display: flex;
    place-items: center;
}

.go-home-page-button-container {
    background: #0c0c0c;
    padding: 7px;
    border-radius: 5px;
}

.go-home-page-button-container:hover {
    background: #2f2f2f;
    cursor: pointer;
    transition-duration: .2s;
}

.go-home-page-button {
    font-size: 13px;
    color: white;
    text-decoration: none;
}

/*MOBILE VIEW*/
@media only screen and (max-width: 768px) {
    .payment-section {
        margin-top: 70px;
        padding: 0;
    }

    .success-message-container {
        padding: 10px;
    }
}
