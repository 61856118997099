.fol-Header {
    display: grid;
    /*padding: clamp(20px, 20px + 2vh, 85px) clamp(20px, 20px + 2vh, 85px) 0;*/
    grid-auto-flow: row;
    gap: 15px 0;
    justify-items: center;
    min-height: 70px;
    /*width: 100vw;*/
    z-index: 10;
    background-image: linear-gradient(to bottom, #00958f, #008661);
}

.fol-MainLogo {
    display: grid;
    margin-top: 30px;
    place-items: center;
    height: 30px;
}

.landing-title {
    color: #fff;
    text-align: center;
}

.landing-select-language-container {
    position: absolute;
    right: 0;
    top: 25px;
}

.fol-MainLogo svg {
    height: 30px;
}

svg {
    display: block;
}

.fol-QrHolder {
    height: 125px;
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: auto auto;
    gap: 10px 20px;
    margin-top: 10px;
    place-items: center;
}

.get-the-app-ios-button {
    border-radius: 20px;
    padding: 10px 20px;
    background-color: #38ff94 !important;
    border: none;
    cursor: pointer;
    outline: none;
    margin-top: 20px;
}

.get-the-app-ios-button-text {
    color: #000000;
    font-weight: 600;
}

.fol-QrTitle {
    font-size: 14px;
    font-weight: 700;
    color: rgb(255, 255, 255);
}

.fol-Header_Bullet {
    font-size: 15px;
    font-weight: 900;
    font-style: italic;
    color: #28ffbb;
    width: fit-content;
    margin: 0 auto;
    display: block;
    text-align: center;
}

.promo a {
    text-decoration: none;
}

.promoLogos__text {
    color: #fff;
    font-size: 14px;
    font-weight: 300;
    margin: 1px 8px 0 0;
}

.promoLogos__direct {
    align-items: center;
    background-color: rgba(0, 0, 0, .4);
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 7px 8px;
}

.promoLogos__arrow {
    background-image: url('../../assets/icons/livesport-right-arrow.png');
    background-size: 100%;
    height: 14px;
    width: 9px;
}

.fol-LayoutContainer {
    display: grid;
    grid-template-rows: minmax(100px, min-content) 5fr 1fr;
    gap: 10px 0;
    top: 0;
    z-index: 10;
    height: 100vh;
    height: 100dvh;
}

.fol-CtaWrapper {
    display: grid;
    justify-items: center;
    gap: clamp(20px, 15px + 3vh, 50px) 0;
    margin: 20px auto 0;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    z-index: 10;
}

.fol-Straps {
    display: grid;
    place-items: center;
}

:host([data-start-strap]) .fol-Strap[data-section=Start] {
    opacity: 1;
}

.fol-GetTheApp {
    height: fit-content;
    position: relative;
    place-self: start center;
    width: 100px;
    display: grid;
    font-family: var(--systemFont);
    font-weight: 700;
    min-height: 35px;
    padding: 0 20px;
    border-radius: 23px;
    transition: all var(--transitionStandard);
    pointer-events: auto;
}

.fol-Strap[data-longest] {
    position: static;
}

.fol-GetTheApp_Text {
    display: grid;
    place-items: center;
    color: var(--sectionPrimary);
    position: absolute;
    top: -9px;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    transition: opacity var(--transitionStandard);
    cursor: pointer;
}

.fol-Strap {
    /*width: clamp(320px, 90vw, 800px);*/
    width: 100%;
    margin: 0;
    font-size: var(--strapSize);
    line-height: calc(1.4* var(--strapSize));
    font-weight: 900;
    font-style: italic;
    text-align: center;
    text-wrap: initial;
    color: #fff;
    /*opacity: 0;*/
    position: absolute;
    transition: opacity var(--transitionStandard);
}

.fol-PhoneFrame {
    position: relative;
    width: 100%;
    display: grid;
    justify-items: center;
    transition: opacity var(--transitionStandard);
}

.fol-PhoneFrame_Inner {
    overflow: hidden;
    position: relative;
    transform: scale(clamp(.8, 1 - var(--Start), 1));
    aspect-ratio: .48 / 1;
    background-repeat: no-repeat;
    background-size: 100%;
    transition: all .2s linear;
    transform-origin: center 100px;
}

.fol-PhoneFrame_Flyout {
    padding: 8px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
}

.fol-PhoneFrameImgLeft {
    transform: none;
    transition: all 1s 1s ease-in-out;
    opacity: 0;
    transform-origin: center right;
}

.fol-PhoneFrameImgRight {
    transition: all 1s 1.3s ease-in-out;
    opacity: 0;
    transform-origin: center left;
    transform: none;
}

.fol-PhoneFrameImg {
    width: 100%;
}

.landing-section img {
    max-width: 100%;
    display: block;
}

.landing-section button {
    appearance: none;
    border: 0;
    background-color: transparent;
}


.fol-PhoneFrameCropper {
    border-radius: 17% / 8%;
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: absolute;
}

:host([data-active-section=Start]) .fol-PhoneFrameItem[data-phone-frame=Start], :host([data-active-section=Fixtures]) .fol-PhoneFrameItem[data-phone-frame=Start] {
    transform: none;
}

[data-phone-frame] {
    /*transform: translate(100%);*/
    transition: transform var(--transitionStandard);
}
.fol-PhoneFrameItem {
    position: absolute;
    overflow: hidden;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 8px;
    z-index: -1;
    border-radius: 35px;
}

.fol-PhoneFrameCropper.fixed {
    position: sticky;
    top: 0; /* Kullanıcının görünümüne göre ayarlayın */
    z-index: 10; /* Diğer elemanların üstünde görünmesi için */
}

.fol-Strap svg {
    opacity: inherit;
    display: inline;
    margin-right: -5px;
    height: calc(.7* var(--strapSize));
}

.fol-Footer {
    position: relative;
    z-index: 1;
    min-height: 100vh;
    overflow: hidden;
    background-image: linear-gradient(to bottom, rgb(14, 133, 98), #28372d);
    padding-top: 50px;
}

.fol-Footer-Inner {
    display: grid;
    place-items: center;
    gap: 35px 0;
    grid-template-rows: 1fr auto;
}

.fol-Footer_Wrapup {
    display: grid;
    gap: clamp(30px, 3vh, 50px);
    grid-auto-flow: row;
    place-items: center;
}

.fol-Footer_MainLogo {
    width: clamp(300px, 50vw, 500px);
    display: flex;
    justify-content: center;
}

.fol-QrTitle {
    font-size: 14px;
    font-weight: 700;
    color: #fff;
}

.fol-Footer_Phone {
    width: clamp(300px, 50vw, 500px);
    align-self: start;
}

.fol-Footer_FrameSetter {
    position: relative;
    border-radius: 45px;
    overflow: hidden;
}

.fol-Footer_PhoneFrameItem {
    position: absolute;
    overflow: hidden;
    top: 8px;
    right: 8px;
    bottom: 8px;
    left: 8px;
    z-index: -1;
    border-radius: 45px;
}

:host([data-getapp-button]) .fol-GetTheApp {
    opacity: 1;
    pointer-events: auto;
}

:host([data-getapp-button]) .fol-JumpMenu {
    opacity: 1;
    pointer-events: auto;
}

.fol-JumpMenu {
    position: absolute;
    z-index: 20;
    left: clamp(20px, 20px + 1vw, 40px);
    top: 50%;
    transform: translateY(calc(-40% - 80px));
    display: grid;
    justify-items: center;
    grid-auto-flow: row;
    opacity: 1;
    /*pointer-events: none;*/
    transition: opacity var(--transitionStandard);
}

.fol-JumpMenu_Up {
    transform: rotate(180deg);
}

.fol-JumpMenu_UpDown {
    background-color: rgba(255, 255, 255, 0.5) !important;
    height: 30px;
    width: 30px;
    margin: 20px 0;
    border-radius: 50%;
    background-image: url('../../assets/icons/chevron.svg');
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
}

.fol-JumpMenuItem {
    height: 40px;
    width: 40px;
    display: grid;
    place-items: center;
}

.fol-JumpMenuItem_Inner {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: relative;
    transition: .3s;
    opacity: 1;
}

@media only screen and (max-width: 450px) {
    .androidPlus .promoLogos__direct {
        margin-right: 16px;
    }

    .androidPlus .promoLogos__text {
        font-size: 12px;
    }

    .fol-QrHolder {
        display: none;
    }
}
