.popup-style {
    position: fixed;
    z-index: 50;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-content-style {
    background: #1F2937FF;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    max-width: 600px;
    max-height: 250px;
}

.warning-popup-title {
    color: #DC2626FF;
}

.popup-warning-text {
    color: #ffffff;
    font-size: 1rem;
}

.close-popup {
    background: #76B49DFF;
    border: none;
    padding: .5rem 1rem;
    border-radius: .25rem;
    color: #ffffff;
    cursor: pointer;
}

@media only screen and (max-width: 768px) {
    .popup-content-style {
        max-width: 400px;
    }

    .warning-popup-title {
        margin: 10px 0;
    }
}

@media only screen and (max-width: 426px) {
    .popup-content-style {
        max-width: 330px;
    }

    .warning-popup-title {
        margin: 5px 0;
    }
}

@media only screen and (max-width: 376px) {
    .popup-content-style {
        max-width: 300px;
    }
}
