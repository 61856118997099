/*.team-result-section {
    margin: 0 10px 0 10px;
}*/

.team-detail-results-table-header {
    display: grid;
    place-items: center;
    grid-template-columns: minmax(120px, auto) 40px 70px repeat(4, 40px);
    background: #E6EFF5;
    padding: 3px 0;
    border-bottom: 1px solid #CBD7E4;
}

.team-detail-results-area {
    margin-left: 5px;
    color: #0C3A7E;
    font-size: 12px;
    font-weight: 600;
    font-family: Verdana, Arial, Helvetica, sans-serif;

}

.results-and-fixtures-country-flag {
    width: 16px;
    height: 12px;
}

.team-detail-results-table-row-container {
    display: grid;
    place-items: center;
    grid-template-columns: 70px 40px 40px minmax(100px, 340px) minmax(50px, auto) minmax(100px, 340px) 40px 40px 40px 70px repeat(4, 40px);
    border-bottom: 1px solid #edf2f4;
    padding: 3px 0;
    border-bottom: 1px solid #e2e2e2;
}

.team-detail-results-table-row-container div {
    font-size: 10px;
}

.team-detail-fixtures-table-row-container {
    display: grid;
    place-items: center;
    grid-template-columns: 70px minmax(140px, 340px) minmax(50px, auto) minmax(140px, 340px) repeat(6, 40px);
    border-bottom: 1px solid #edf2f4;
    padding: 3px 0;
    border-bottom: 1px solid #e2e2e2;
}

.team-detail-fixtures-table-row-container div{
    font-size: 10px;
}

.results-match-date {
    color: #444444;
}

.team-detail-tables-header {
    color: #444444;
    font-size: 10px;
    font-weight: 700;
}

.team-detail-tables-header-odd {
    color: #444444;
    font-size: 10px;
    font-weight: 700;
}

.team-detail-results-team-name {
    color: #1a3150;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.team-detail-results-home-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    cursor: pointer;
}

.team-detail-results-home-team {
    text-align: right;
}

.team-detail-results-away-container {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    cursor: pointer;
}

.team-detail-results-score {
    color: #0C3A7E;
    font-weight: bold;
    cursor: pointer;
}

.team-detail-results-ht-score {
    color: #444444;
}

.match-reuslts-icons-container {
    display: flex;
}

.match-results-icons {
    width: 14px;
    height: 14px;
}

.team-details-results-date-container {
    display: flex;
    width: 100%;
    place-items: center;
    padding-left: 10px;
}

@media only screen and (max-width: 768px) {
    .team-detail-results-table-header {
        grid-template-columns: minmax(120px, auto) repeat(4, 40px);
    }

    .team-detail-results-table-row-container {
        grid-template-columns: 70px  minmax(140px, 180px) minmax(50px, auto) minmax(140px, 180px) repeat(4, 40px);
    }

    .team-detail-fixtures-table-row-container {
        grid-template-columns: 60px minmax(150px, 340px) minmax(40px, auto) minmax(150px, 340px) 50px;
    }

    .team-detail-tables-header {
        display: none;
    }

    .team-info-general-section {
        margin: 0;
    }

    .team-detail-and-tabs-section {
        margin: 10px 0 0 0;
    }

    .team-details-results-date-container {
        padding-left: 10px;
    }
}

@media only screen and (max-width: 590px) {
    .team-detail-results-table-header {
        grid-template-columns: minmax(120px, auto);
        display: flex;
        justify-content: flex-start;
    }

    .team-detail-results-table-row-container {
        grid-template-columns: 70px  minmax(140px, 180px) minmax(50px, auto) minmax(140px, 180px);
    }

    .team-detail-fixtures-table-row-container {
        grid-template-columns: 70px  minmax(100px, 180px) minmax(50px, auto) minmax(100px, 180px);
    }

    .team-detail-tables-header-odd {
        display: none;
    }

    .calculateOdd {
        display: none;
    }

    .match-reuslts-icons-container {
        display: none;
    }

    .compare-icon {
        display: none;
    }
}
