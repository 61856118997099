.live-scores-section {
    padding: 10px 0;
    min-height: 500px;
}

.live-scores-texts-12px {
    font-size: 12px;
    cursor: pointer;
}

.live-scores-date-time-picker {
    display: flex;
    place-items: center;
    border: 1px solid #dadada;
    border-radius: 4px;
}

.live-scores-date-time-picker .MuiOutlinedInput-notchedOutline {
    border: none !important;
    border-right: 1px solid #dadada !important;
    border-left: 1px solid #dadada !important;
    border-radius: 0 !important;
}

.arrow-img {
    width: 20px;
    height: 20px;
}

.date-arrow-container {
    display: grid;
    place-items: center;
    padding: 0 5px;
    cursor: pointer;
}

.live-scores-date-time-picker input {
    padding: 5px;
    text-align: right;
    font-weight: 600;
    font-size: 13px;
    cursor: pointer;
    color: #333;
}

.reverse-right-arrow-icon {
    rotate: -180deg;
}

.live-scores-general-container {
    width: 100%;
}

.live-scores-match-team-names {
    color: #0c0c0c;
    width: 100%;
    font-size: 12px;
    display: flex;
    justify-content: flex-end;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.fav-icon {
    cursor: pointer;
}

.live-scores-cards-container {
}

.live-scores-matches-container {
    height: 28px;
    display: flex;
    place-items: center;
    border-bottom: 1px solid #f6f6f6;
}

.live-scores-week-days-and-dtp-section {
    display: flex;
    place-items: center;
}

.live-scores-top-header {
    display: flex;
    justify-content: space-between;
    padding: 0 5px;
    background: white;
    border-radius: 5px 5px 0 0;
    place-items: center;
    min-height: 52px;
}

.live-scores-top-header-mobile {
    display: none;
}

.stats-button-container {
    display: flex;
    border: 1px solid #e1e7ed;
    border-radius: 8px;
}

.stat-button {
    padding: 7px 20px;
    cursor: pointer;
    font-size: 13px;
    font-weight: 500;
    color: #162b46;
    border-left: 1px solid #e1e7ed;
    transition-delay: .1s;
    transition-duration: .2s;
}

.stat-button:first-child {
    border-left: none;
    border-radius: 8px 0 0 8px;
}

.stat-button:last-child {
    border-radius: 0 8px 8px 0;
}

.active-stat-button {
    background: red;
    border-radius: 2.5px;
    padding: 5px 10px;
}

.country-flag {
    width: 20px;
    height: 13px;
}

.live-scores-league-name-container {
    height: 28px;
    background: #e7e7e7;
    padding-left: 10px;
}

.live-scores-area-info-container {
    padding-left: 10px;
    font-size: 12px;
    font-weight: 600;
}

.live-scores-table-section {
    height: 100%;
    width: 100%;
    border-collapse: collapse;
}

.live-scores-table-header-container {

}

/*.stats-table-section tr td {
    border-left: 1px solid #edf2f4;
}*/

.stats-table-header tr td {
    /*border-left: 1px solid #dce0e7;*/
    height: 100%;
}

.live-scores-table-row {
    background: white;
}

.live-scores-section-mobile {
    display: none;
}

.favorites-row-for-header {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.match-info-td {
}

.live-scores-match-info-container {
    height: 100%;
    display: grid;
    place-items: center;
    grid-template-columns: 35px 40px 50px minmax(140px, 250px) minmax(50px, auto) minmax(140px, 250px) 50px;
}

.live-scores-1-half {
    font-size: 12px;
}

.live-scores-odds {
    font-size: 10px;
    font-weight: 600;
}

.live-scores-away-team-name {
    justify-content: flex-start;
}

.league-name-and-flag-container {
    display: flex;
    place-items: center;
}

.league-name-and-flag {
    display: flex;
    cursor: pointer;
}

.stats-container {
    text-align: center;
    width: 10%;
    border-bottom: 2px solid green;
}

.stats-type-mobile {
    display: none;
}

.stats-container1 {
    text-align: center;
    width: 10%;
    border-bottom: 2px solid orange;
}

.stats-header-container {
    text-align: center;
    width: 10%;
}

.stats-header-red {
    /*width: 12%;*/
    flex: 1;
    text-align: center;
}

.live-scores-stats-header-other {
    width: 4%;
    text-align: center;
}

.stat-style-width-red {
    width: 12%;
    text-align: center;
    height: 100%;
}

.live-scores-stat-style-width-other {
    width: 4%;
    text-align: center;
    height: 100%;
}

.stat_yellow {
    text-align: center;
    width: 50%;
    display: flex;
    place-items: center;
    justify-content: center;
}

.stat_red {
    text-align: center;
    width: 50%;
    display: flex;
    place-items: center;
    justify-content: center;
}

.no-info-stats {
    border-bottom: 1.5px solid black !important;
    /*width: 36%;*/
}

.stat_green {
    text-align: center;
    width: 50%;
    display: flex;
    place-items: center;
    justify-content: center;
}

.match-card.yellow-card {
    background-color: #ffcc05;
    color: #000;
}

.match-card.red-card {
    background-color: #CA2027;
    color: white;
}

.no-info-stats-row {
    display: flex;
    justify-content: center;
    height: 100%;
    place-items: center;
}

.card-container {
    display: flex;
}

.match-card {
    display: flex;
    place-items: center;
    justify-content: center;
    padding: 0 2px;
    border-radius: 2px;
    width: 8px;
    height: 16px;
    font-size: 11px;
    font-weight: 700;
}

.live-container {
    border-radius: 9px;
    cursor: pointer;
}

.not-live-icon {
    background-color: #333;
    border-radius: 10px;
    padding: 2px;
    width: 15px;
}


.live-scores-live-match {
    display: flex;
    place-items: center;
    border-radius: 9px;
    justify-content: space-between;
    padding: 7px;
    background-color: #333;
    transition-delay: .1s;
    transition-duration: .3s;
    position: relative;
}

.live-scores-live-status-section {
}

.live-text {
    color: white;
    font-size: 13px;
    font-weight: 500;
    margin-left: 5px;
}

.live-match-min {
    color: var(--H3Red01) !important;
    font-weight: 700;
}

.live-match-score {
    color: var(--H3Red01);
    font-weight: 700;
}

.score-container {

}

.live-match-count {
    z-index: 0;
    color: #fff;
    border-radius: 15px;
    font-weight: 600;
    font-size: 10px;
    padding: 3px 5px;
    line-height: 1;
    border: 2px solid #fff;
    background: red;
    position: absolute;
    right: -7px;
    top: -8px;
}

.no-live-live-scores-matches-container {
    display: flex;
    justify-content: center;
    place-items: center;
}

.no-live-matches-text {
    font-size: 13px;
    font-weight: 700;
    color: #6f8caa;
}

.live-scores-week-days-section {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    place-items: center;
    padding: 0 15px;
    max-width: 400px;
}

.live-scores-week-days-container {
    display: flex;
    justify-content: center;
    margin: 0 10px;
}

.live-scores-dates {
    font-size: 12px;
    font-weight: 700;
    color: #6b6b6b;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    place-items: center;
    text-transform: uppercase;
    transition-delay: .1s;
    transition-duration: .2s;
}

.live-scores-active-week-day {
    color: white !important;
    font-weight: 600;
    font-size: 10px;
    display: flex;
    place-items: center;
    background: #0c0c0c;
    border-radius: 20px;
    padding: 5px 10px;
}

.live-scores-date-day-name {
    font-size: 10px;
}

.live-scores-date-margin-left {
    margin-left: 5px;
}

.score-container {
    text-decoration: none;
}

/*MOBILE VIEW*/
@media only screen and (max-width: 768px) {
    .live-scores-section {
        padding: 0;
        min-height: 500px;
    }

    .live-scores-table-row {
        height: 1.9rem;
    }

    .live-scores-top-header {
        display: none;
    }

    .live-scores-table-header-container {
        display: none;
    }

    .live-scores-stat-style-width-other {
        display: none;
    }

    .stats-header-red {
        display: none;
    }

    .stats-header-other {
        display: none;
    }

    .live-scores-section-mobile {
        display: flex;
    }

    .favorites-row-for-header {
        display: none;
    }

    /*.league-name-container {
        display: none;
    }*/

    .live-scores-top-header-mobile {
        display: flex;
        flex-direction: column;
        padding: .5rem 0 .5rem 0;
        background: white;
    }

    .live-scores-active-week-day {
        padding: 3px 10px;
    }

    .live-scores-match-info-container {
        grid-template-columns: 35px 40px auto minmax(70px, 110px) 50px minmax(70px, 110px) auto;
    }

    .live-scores-match-team-names {
        font-size: 11px;
    }

    .live-scores-live-status-container {
        margin-left: .3rem;
    }

    .live-and-weekdays-container-mobile {
        display: flex;
        place-items: center;
    }

    .stats-button-container {
        display: flex;
        border: none;
        padding: .3rem;
    }

    .stat-button {
        flex: 1;
        text-align: center;
        padding: .5rem 0;
        cursor: pointer;
        font-size: 12px;
        font-weight: 500;
        border-left: none;
    }

    .stats-header-container {
        display: none;
    }

    .stat_yellow {
        flex: 1;
        border-left: 1px solid #e2e3e8;
        min-height: 2rem;
        display: grid;
        place-items: center;
    }

    .stat_red {
        flex: 1;
        border-left: 1px solid #e2e3e8;
        min-height: 2rem;
        display: grid;
        place-items: center;
    }

    .stat_green {
        flex: 1;
        border-left: 1px solid #e2e3e8;
        min-height: 2rem;
        display: grid;
        place-items: center;
    }

    /*.stats-type-mobile {
        display: flex;
        color: #6f8caa;
    }*/

    .live-scores-stat-style {
        color: #00b1ff;
        font-size: .7rem;
    }

    .stat-style-width-red {
        width: 100%;
    }

    .stat-style-width-other {
        width: 100%;
    }

    .no-info-stats {
        width: 100%;
    }

    .dates {
        font-size: 11px;
        font-weight: 700;
    }

    .active-week-day {
        color: white;
        font-weight: 700;
        font-size: 11px;
        background: #0c0c0c;
        border-radius: 20px;
        padding: 3px 10px;
        transition-delay: .1s;
        transition-duration: .2s;
    }

    .live-scores-area-info-container {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 370px;
    }
}

@media only screen and (max-width: 426px) {
    /*.live-scores-match-info-container {
        grid-template-columns: 25px 35px 20px minmax(70px, 115px) 30px minmax(70px, 115px) 20px 35px 25px;
    }*/
    .live-scores-match-info-container {
        grid-template-columns: 20px 35px 30px minmax(70px, 130px) minmax(50px, auto) minmax(70px, 130px) 30px;
    }

    .live-scores-stats-header-other {
        display: none;
    }

    .live-scores-area-info-container {
        width: 340px;
    }
}

@media only screen and (max-width: 376px) {

    .live-scores-area-info-container {
        width: 310px;
    }
}
