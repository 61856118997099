.general-linear-section {
    display: none;
}

.linear-container {
    display: flex;
    border-radius: 10px;
    padding: 10px;
}

.linear-container-text {
    display: flex;
    place-items: center;
    font-size: 12px;
    color: #efefef;
    font-weight: 600;
}

.premium-icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

@media only screen and (max-width: 768px) {
    .general-linear-section {
        display: block;
        padding: 3px;
        position: sticky;
        top: 0;
        bottom: 0;
    }
}


