
.live-scores-general-section {
    background: white;
    width: 100%;
    margin-top: 10px;
}

.live-scores-live-and-dates-container-web {
    display: flex;
    justify-content: space-between;
    padding: 8px 10px;
    box-sizing: border-box;
    background: var(--H3Light0);
}

.live-scores-live-and-dates-container-mobile {
    display: none;
}

.score-radar-flag-and-league-container {
    display: flex;
    align-items: center;
}

.score-radar-area-info-container {
    display: flex;
    place-items: center;
    padding-left: 10px;
    font-size: 12px;
    font-weight: 600;
}

.score-radar-country-flag {
    width: 20px;
    height: 13px;
    padding-left: 5px;
}

.score-radar-league-name-container {
    height: 28px;
    background: var(--H3Light2);
    color: var(--H3Dark1);
    display: flex;
    place-items: center;
}

.score-radar-league-name-and-flag-container {
    flex: 8;
}

.score-radar-table-header-container {
    display: flex;
    justify-content: center;
}

.live-container {
    border-radius: 9px;
    cursor: pointer;
}

.postponed-status {
    color: var(--H3Red01);
    font-weight: 600;
}

.not-live-icon {
    background-color: #1a3150;
    border-radius: 10px;
    padding: 2px;
    width: 15px;
}

.live-text {
    color: white;
    font-size: 13px;
    font-weight: 500;
    margin-left: 5px;
}

.live-match-min {
    color: var(--H3Red01) !important;
    font-weight: 700;
}

.score-container-bet-mode {

}

.bet-mode-stats-header {
    flex: 1;
    text-align: center
}

.live-match-count {
    z-index: 0;
    color: #fff;
    border-radius: 15px;
    font-weight: 600;
    font-size: 10px;
    padding: 3px 5px;
    line-height: 1;
    border: 2px solid #fff;
    background: red;
    position: absolute;
    right: -7px;
    top: -8px;
}

.no-live-matches-container {
    display: flex;
    justify-content: center;
    place-items: center;
}

.no-live-matches-text {
    font-size: 13px;
    font-weight: 700;
    color: #6f8caa;
}

.sr-week-days-section {
    display: flex;
    width: 40%;
    place-items: center;
    padding: 0 15px;
}

.week-days-container {
    display: flex;
    flex: 1;
    justify-content: center;
}

.bet-mode-dates {
    font-size: 12px;
    font-weight: 700;
    color: var(--H3MidGrey1);
    cursor: pointer;
    display: flex;
    place-items: center;
    text-transform: uppercase;
    transition-delay: .1s;
    transition-duration: .2s;
}

.bet-mode-active-week-day {
    color: var(--H3Light1);
    font-size: 10px;
    display: flex;
    place-items: center;
    background: var(--H3Dark1);
    border-radius: 20px;
    padding: 5px 12px;
}

.bet-mode-date-day-name {
    font-size: 10px;
}

.bet-mode-date-margin-left {
    margin-left: 5px;
}

.live-scores-general-table-section {
    border-bottom: 1px solid var(--H3Light1);
}

.live-scores-fixture-table-row {
    background: var(--H3Light0);
    display: flex;
}

.bet-mode-area-info-container {
    padding-left: 10px;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    text-decoration: none;
    color: var(--H3Dark2);
}

.bet-mode-matches-container {
    display: flex;
    place-items: center;
}

.bet-mode-match-info-container {
    height: 28px;
    display: grid;
    place-items: center;
    /*grid-template-columns: 30px 40px 35px minmax(140px, 250px) minmax(50px, auto) minmax(140px, 250px) 35px;*/
    grid-template-columns: 35px 40px 50px minmax(140px, 250px) minmax(50px, auto) minmax(140px, 250px) 50px;
}

.live-scores-stats-td-section {
    /*width: 7%;*/
    border-left: 1px solid var(--H3Light1);
    flex: 1;
    display: flex;
    justify-content: center;
}

.live-scores-stats-container {
    display: flex;
    justify-content: center;
}

.live-scores-stats-tr-container {
    display: grid;
    place-items: center;
    width: 100%;
    text-align: center;
}

.live-scores-stats-name-container {
    width: 100%;
}

.live-scores-stats-name {
    font-weight: 600;
    font-size: 11px;
    color: #6b6b6b;
}

.live-scores-stats-text-container {
    width: 100%;
}

.live-scores-stats {
    font-size: 12px;
}

/*TABLET VIEW*/
@media only screen and (max-width: 942px) {
    .bet-mode-match-info-container {
        grid-template-columns: 30px 40px 35px minmax(105px, 130px) minmax(50px, auto) minmax(105px, 130px) 35px;
    }

    .score-radar-table-header-container {
        display: block;
    }
}

/*MOBILE VIEW*/
@media only screen and (max-width: 768px) {
    .fixture-section {
        padding: 0;
        min-height: 500px;
    }

    .score-container-bet-mode {
        background: var(--H3Light1);
        padding: 5px 7px;
        margin: 0 5px;
        border-radius: 5px;
    }

    .live-scores-stats-name {
        font-size: 10px;
    }

    .score-radar-league-name-and-flag-container {
        display: flex;
    }

    .live-scores-live-and-dates-container-web {
        display: none;
    }

    .sr-week-days-section {
        width: 75%;
    }

    .live-scores-live-and-dates-container-mobile {
        display: flex;
        flex-direction: column;
        padding: 5px;
    }

    .live-scores-general-section {
        margin-top: 0;
    }

    .bet-mode-matches-container {
        display: none;
    }

    .fixture-table-row {
        height: 2rem;
        border-bottom: 1px solid #e0e6f0;
    }

    .fixtures-top-header {
        display: none;
    }

    .matches-container {
        display: none;
    }

    .stats-header-red {
        display: none;
    }

    .stats-header-other {
        display: none;
    }

    .score-radar-league-name-container {
        display: flex;
    }

    .bet-mode-stats-header {
        display: none;
    }

    .score-radar-table-header-container {
        display: none;
    }

    .fixtures-section-mobile {
        display: flex;
    }

    .fixtures-top-header-mobile {
        display: flex;
        flex-direction: column;
        padding: .5rem 0 .1rem 0;
        background: white;
    }

    .bet-mode-match-info-container {
        /*grid-template-columns: 30px 40px 35px minmax(70px, 110px) minmax(40px, auto) minmax(70px, 110px) 35px 40px 30px;*/
        grid-template-columns: 35px 30px minmax(70px, 130px) minmax(40px, auto) minmax(70px, 130px) 30px 35px;
    }

    .bet-mode-start-time-for-mobile {
        font-size: 12px;
        color: var(--H3Text2);
        font-weight: 500;
    }

    .live-scores-active-week-day {
        border-radius: 20px;
        padding: 3px 10px;
    }

    .date-day-name {
        font-size: 11px;
        margin-left: 0;
    }

    .live-scores-stats-td-section {
        width: 10%;
        background: var(--div-bg02);
        min-height: 2.5rem;
        border-left: 2px solid var(--H3Light1);
    }

    .match-team-names {
        font-size: 12px;
    }

    .live-status-container {
        margin-left: .3rem;
    }

    .live-and-weekdays-container-mobile {
        display: flex;
        place-items: center;
    }

    .stats-button-container {
        display: flex;
        border: none;
        padding: .3rem;
    }

    .stat-button {
        flex: 1;
        text-align: center;
        padding: .5rem 0;
        cursor: pointer;
        font-size: 12px;
        font-weight: 500;
        border-left: none;
    }

    .stats-header-container {
        display: none;
    }

    .stat_yellow {
        flex: 1;
        border-left: 1px solid #e2e3e8;
        min-height: 2rem;
        display: grid;
        place-items: center;
    }

    .stat_red {
        flex: 1;
        border-left: 1px solid #e2e3e8;
        min-height: 2rem;
        display: grid;
        place-items: center;
    }
    .stat_green {
        flex: 1;
        border-left: 1px solid #e2e3e8;
        min-height: 2rem;
        display: grid;
        place-items: center;
    }

    /*.stats-type-mobile {
        display: flex;
        color: #6f8caa;
    }*/

    .fixture-stat-style {
        color: #00b1ff;
        font-size: .7rem;
    }

    .stat-style-width-red {
        width: 100%;
    }

    .stat-style-width-other {
        width: 100%;
    }

    .no-info-stats {
        width: 100%;
    }

    .dates {
        font-size: 11px;
        font-weight: 700;
    }

    .active-week-day {
        color: white;
        font-weight: 700;
        font-size: 11px;
        background: #0c0c0c;
        border-radius: 20px;
        padding: 3px 15px;
        transition-delay: .1s;
        transition-duration: .2s;
    }
    .bet-mode-area-info-container {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 370px;
    }
}

@media only screen and (max-width: 426px) {
    /*.bet-mode-match-info-container {
        grid-template-columns: 25px 35px 20px minmax(70px, 115px) 40px minmax(70px, 115px) 20px 35px 25px;
    }*/

    .bet-mode-area-info-container {
        width: 340px;
    }
}

@media only screen and (max-width: 376px) {
    .bet-mode-area-info-container {
        width: 310px;
    }
}
