.premium-section {
    background: #141F31;
    margin: 10px 0;
    border-radius: 5px;
    padding: 10px 0;
    display: grid;
    place-items: center;
    width: 100%;
}

.cancel-subscription-section {
    background: var(--H3Light0);
    margin: 10px 0;
    border-radius: 5px;
    padding: 10px 0;
    display: grid;
    place-items: center;
    width: 100%;
}

.cancel-subscription-container {
    width: 70%;
}

.premium-container {
    max-width: 70%;
}

.top-premium-info-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    place-items: center;
}

.premium-button-container {
    display: flex;
    width: 100%;
}

.join-premium-button {
    text-decoration: none;
    color: white;
    background: #2bd777;
    padding: 8px;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    border: none;
    width: 100%;
    cursor: pointer;
    text-align: center;
}

.join-premium-button:hover {
    text-decoration: none;
    color: white;
    background: #25e17a;
    padding: 8px;
    border-radius: 5px;
}

.already-premium-text {
    color: white;
    font-size: 14px;
    font-weight: 600;
}

.premium-top-left-container {
    max-width: 340px;
}

.fv-premium-title {
    color: #34F188;
    margin: 5px 0;
    font-size: 14px;
}

.unlock-everything-text {
    font-size: 2rem;
    font-weight: 800;
    margin: 10px 0;
    color: white;
}

.get-access-text {
    color: #B9C5DA;
    font-size: 14px;
}

.limited-price-section {
    display: flex;
    flex-direction: column;
    place-items: center;
    min-width: 200px;
}

.limited-price-container {
    display: flex;
    place-items: center;
}

.join-premium-country-flag {
    width: 20px;
    height: 15px;
}

.limited-price-text {
    color: white;
    font-size: 14px;
    margin: 5px 0 5px 5px;
}

.join-premium-payment-container {
    display: grid;
    place-items: center;
    width: 100%;
    background: #18253F;
    padding: 10px;
    border-radius: 10px;
}

.price-container {
    display: flex;
    justify-content: space-between;
    padding: 0  0 8px 0;
}

.old-price {
    color: #4F5D76;
    text-decoration-line: line-through;
}

.price {
    color: white;
}

.month-text1 {
    font-size: 11px;
    color: #4F5D76;
    font-weight: 600;
}

.month-text {
    font-size: 12px;
    color: white;
    font-weight: 600;
}

.save-text {
    color: #34F188;
    font-size: 14px;
    font-weight: 600;
    margin-left: 20px;
}

.features-and-benefits-section {
    display: grid;
    place-items: center;
    width: 100%;
}

.features-lists-container {
    display: grid;
    grid-template-columns: repeat(2, minmax(200px, auto));
    padding: 10px 0;
}

.flag-and-title-general-container {
    margin: 5px 0;
}

.flag-and-title-container {
    display: flex;
    place-items: center;
    margin: 5px 0;
}

.join-premium-flag {
    width: 18px;
    height: 18px;
    background: white;
    padding: 5px;
    border-radius: 5px;
}

.features-title {
    font-size: 17px;
    color: white;
    margin: 5px 0 5px 7px;
}

.features-text {
    color: #B9C5DA;
    font-size: 14px;
}

.must-login-first-text {
    font-size: 12px;
    color: white;
    text-align: right;
}

.none-user-premium-container {
    width: 100%;
    display: grid;
    place-items: center;
}

.none-user-premium-container .sign-in-button-container a{
    text-decoration: none;
}

.none-user-premium-container .sign-in-button-container {
    margin-top: 10px;
    width: 100%;
    padding: 5px 0;
}

.membership-container {
    border-bottom: 1px solid #efefef;
}

.your-membership-title {
    color: var(--H3Dark1);
}

.membership-text {
    color: var(--H3Text2);
}

.account-type-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
}

.account-type-title {
    margin: 10px 0;
    font-size: 15px;
    color: var(--H3Dark1);
}

.account-type-input {
    width: 35%;
}

.text-style {
    font-size: 13px;
    margin: 10px 0 5px 0;
    color: var(--H3Dark1);
}

.cancel-sub-question-text {
    color: var(--H3Red01);
    font-weight: 600;
    font-size: 13px;
    margin: 5px 0;
}

.text-field-and-cancel-button-container {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
}

.cancel-subs-button {
    margin: 15px 0;
    max-width: 30%;
    padding: 10px;
    border: none;
    background: var(--H3Red01);
    color: white;
    font-size: 13px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
}

.cancel-subs-button:hover {
    background: #ff4040;
    transition-duration: .2s;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.9);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

.jp-popup-content-style {
    background: #1F2937FF;
    padding: 15px;
    border-radius: 10px;
    text-align: center;
    max-width: 600px;
    max-height: 250px;
    animation: fadeIn 0.2s ease-out;
    opacity: 0;
    animation-fill-mode: forwards;
}

.jp-close-button-container {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}

.jp-warning-popup-title {
    color: #DC2626FF;
}

.jp-popup-warning-text {
    color: #ffffff;
    font-size: 1rem;
}

.jp-close-popup {
    width: 20px;
    height: 20px;
}

.jp-go-join-premium-button {
    background: #76B49DFF;
    border: none;
    padding: .5rem 1rem;
    border-radius: .25rem;
    color: #ffffff;
    cursor: pointer;
}



/*MOBILE VIEW*/
@media only screen and (max-width: 768px) {
    .premium-section {
        margin-top: 10px;
    }

    .top-premium-info-container {
        display: grid;
    }

    .limited-price-section {
        margin-top: 10px;
        width: 100%;
    }

    .features-lists-container {
        display: grid;
        grid-template-columns: repeat(1, minmax(200px, auto));
    }

    .cancel-subscription-section {
        margin-top: 70px;
    }

    .cancel-subs-button {
        margin: 15px 0;
        max-width: 60%;
    }

    .jp-popup-content-style {
        max-width: 400px;
    }
}

@media only screen and (max-width: 426px) {
    .jp-popup-content-style {
        max-width: 330px;
    }

    .jp-warning-popup-title {
        margin: 5px 0;
    }
}

@media only screen and (max-width: 376px) {
    .jp-popup-content-style {
        max-width: 300px;
    }
}
