.fixture-section {
    padding: 10px 0;
    min-height: 500px;
}

.fixtures-general-container {
    width: 100%;
}

.no-matches-that-match-filter {
    display: flex;
    justify-content: center;
    background: #e7e7e7;
    font-size: 13px;
    font-weight: 700;
    color: #6f8caa;
}

.match-team-names {
    font-size: 12px;
    color: var(--H3Dark1);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.fixtures-cards-container {
}

.matches-container {
    height: 32px;
    display: flex;
    place-items: center;
    border-bottom: 1px solid var(--H3Light1);
}

.fixtures-top-header {
    display: flex;
    justify-content: space-between;
    padding: 0 5px;
    background: var(--H3Light0);
    border-radius: 5px 5px 0 0;
    place-items: center;
    min-height: 52px;
}

.select-stats-dialog {
    display: flex;
    flex-direction: column;
    min-width: 550px;
    background: #ffffff;
    overflow-x: hidden;
}

.all-stats-ul-container {
    list-style-type: none;
    padding-left: 20px;
    margin-top: 10px;
}

.all-stats-ul-container li:hover{
}

.selected-stat-style {
    background: #ececec;
}

.stat-style {
    /*color: #9b9b9b;*/
    color: #656565;
    font-size: 14px;
    cursor: pointer;
    padding: 5px;
}

.selected-stat-style:hover {
    background: #efefef;
}

.stat-style:hover {
    background: #efefef;
}

.table-stats-header {
    color: var(--H3Dark2);
    font-weight: 600;
    font-size: 11px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.stats-top-header-mobile {
    display: none;
}

.stats-button-container {
    display: flex;
    border: 1px solid var(--H3MidGrey1);
    border-radius: 10px;
}

.stat-button {
    padding: 7px 10px;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    color: var(--H3MidGrey1);
    border-left: 1px solid var(--H3MidGrey1);
    transition-delay: .1s;
    transition-duration: .2s;
}

.stats-mobile-button-container {
    display: flex;
    /*border: 1px solid var(--H3MidGrey1);*/
    border-radius: 10px;
    background: var(--H3Light1);
}

.stat-button-for-mobile {
    flex: 1;
    text-align: center;
    padding: 6px 0;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    color: var(--H3Dark3);
    transition-delay: .1s;
    transition-duration: .2s;
}

.stat-button:first-child {
    border-left: none;
    border-radius: 8px 0 0 8px;
}

.stat-button:last-child {
    border-radius: 0 8px 8px 0;
}

.active-stat-button {
    background: red;
    border-radius: 2.5px;
    padding: 5px 10px;
}

.country-flag {
    width: 20px;
    height: 13px;
}

.league-name-container {
    height: 28px;
    background: #e2e2e2;
    color: var(--H3Dark1);
    display: flex;
    place-items: center;
}

/*.league-name-container:hover {
    background: var(--H3Light1);
}*/

.league-name-container-for-mobile {
    height: 28px;
    background: var(--H3Light2);
    color: var(--H3Dark1);
    display: flex;
}

.stats-container-for-web {
    display: flex;
    justify-content: center;
    font-size: 11px;
}

.stats-container-for-mobile {
    display: none;
}

.area-info-container {
    padding-left: 10px;
    font-size: 12px;
    font-weight: 600;
}

.fixtures-table-section {
    height: 100%;
    width: 100%;
    border-collapse: collapse;
    display: grid;
}

.top-select-stats-list-container {
    display: flex;
    place-items: center;
    flex: 1;
    margin: 0 10px 0 0;
    min-width: 15%;
    background: var(--H3MidGrey1);
    border-radius: 5px;
}

.top-search-matches-container {
    display: flex;
    place-items: center;
    flex: 1;
    margin: 0 10px 0 0;
    min-width: 20%;
    background: var(--H3MidGrey1);
    border-radius: 5px;
}

.stats-select-container {
    padding: 5px;
    width: 100%;
    cursor: pointer;
    border-radius: 5px;
    background: #f5f5f5;
    border-color: #0000003A;
}

.filter-icon {
    width: 25px;
    height: 20px;
    margin: 0 5px;
}

.search-icon {
    width: 25px;
    height: 20px;
    margin: 0 2px;
}

.filter-button {
    display: inline-block;
    background: #f5f5f5;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    border: 1px solid #cacaca;
    padding: 5px;
}

.filter-button::before {
    display: inline-block;
    margin-right: 5px;
}

.filter-button::after {
    content: "▼";
    display: inline-block;
    margin-left: 5px;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.filter-button:hover {
    background: #f1f1f1;
}

.top-select-stats-list-container div div{
    background: #ffffff;
    color: #0C0C0CFF;
}

.top-select-stats-list-container div label{
    background: #FFFFFFFF;
    border-radius: 5px 5px 0 0;
    color: #0C0C0CFF;
}

.stats-selection-input-label {
    background: white;
    padding-left: 5px;
}

.top-select-stats-list-container .MuiFormControl-root div {
    padding: 3px;
    border-radius: 10px;
}

/*.stats-table-section tr td {
    border-left: 1px solid #edf2f4;
}*/

.stats-table-header tr td {
    /*border-left: 1px solid #dce0e7;*/
    height: 100%;
}

.fixture-table-row {
    background: var(--H3Light0);
    display: flex;
    height: 100%;
}

.fixtures-section-mobile {
    display: none;
}

.match-info-container {
    height: 100%;
    display: grid;
    place-items: center;
    /*grid-template-columns: 35px 40px 35px minmax(120px, 160px) minmax(40px, auto) minmax(120px, 160px) 35px 40px;*/
    /*grid-template-columns: 35px 40px 35px minmax(130px, 150px) minmax(40px, auto) minmax(130px, 150px) 35px 40px;*/
    grid-template-columns: 35px 40px 35px 35px 35px minmax(120px, 200px) minmax(40px, auto) minmax(120px, 200px) 35px 35px 45px 45px;
}

.match-info-container-for-avg {
    height: 100%;
    display: grid;
    place-items: center;
    /*grid-template-columns: 35px 40px 35px minmax(200px, 230px) minmax(40px, auto) minmax(200px, 230px) 35px 40px;*/
    /*grid-template-columns: 35px 40px 35px 35px 35px minmax(150px, 220px) minmax(40px, auto) minmax(150px, 220px) 35px 35px 45px 45px;*/
    grid-template-columns: 35px 40px 35px 35px 35px minmax(150px, 300px) minmax(40px, auto) minmax(150px, 300px) 35px 35px 45px 45px;
}

.match-info-td {
    flex: 8;
}

.match-team-name {
    /*display: flex;
    justify-content: flex-end;*/
    text-align: right;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    width: 100%;
}

.away-team-name {
    text-align: left;
}

.league-name-and-flag-container {
    display: grid;
    place-items: center;
    grid-template-columns: auto 100px 45px 45px;
    flex: 8;
}

.league-name-and-flag-container-for-show-more-matches {
    display: grid;
    place-items: center;
    grid-template-columns: auto 100px;
    flex: 8;
    cursor: pointer;
}

.league-name-and-flag {
    display: flex;
    cursor: pointer;
    text-decoration: none;
    color: var(--H3Dark2);
}

.stats-container {
    text-align: center;
    width: 10%;
    border-bottom: 2px solid green;
}

.stats-type-mobile {
    display: none;
}

.stats-value-mobile {
    display: none;
}

.stats-container1 {
    text-align: center;
    width: 10%;
    border-bottom: 2px solid orange;
}

.stats-header-container {
    text-align: center;
    width: 10%;
}

.stats-header-red {
    /*width: 12%;*/
    flex: 1;
    text-align: center;
}

.stats-header-other {
    /*width: 7%;*/
    flex: 1;
    text-align: center;
}

.stats-header-odds {
    /*width: 7%;*/
    flex: 1;
    text-align: center;
}

.stats-title-container {
    display: grid;
    place-items: center;
    height: 100%;
}

.stat-style-width-red {
    /*width: 12%;*/
    text-align: center;
    height: 100%;
    flex: 1;
    display: grid;
    place-items: center;
}

.stat-style-width-other {
    /*width: 9%;*/
    flex: 1;
    height: 100%;
    display: grid;
    place-items: center;
}

.stat-style-width-odds {
    /*width: 9%;*/
    flex: 1;
    height: 100%;
    display: grid;
    place-items: center;
}

.stat_yellow {
    text-align: center;
    width: 50%;
    background: #f6f7cd;
    display: flex;
    place-items: center;
    justify-content: center;
}

.stat_red {
    text-align: center;
    width: 50%;
    background:  #ffe9e6;
    display: flex;
    place-items: center;
    justify-content: center;
}

.stats-matches-referee-name-td-container {
    flex: 3;
    display: grid;
    place-items: center;
    justify-content: flex-end;
}

.stats-matches-referee-name-container {
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
}

.stats-matches-referee-name {
    font-size: 11px;
    color: var(--TextPrimary);
}

.no-odds-info-stats {
    /*border-bottom: 1.5px solid var(--H3Dark1) !important;*/
    /*width: 36%;*/
    flex: 5.08;
}

.no-info-stats {
    /*border-bottom: 1.5px solid var(--H3Dark1) !important;*/
    /*width: 36%;*/
    flex: 4.09;
}

.no-info-stats-for-avg {
    /*border-bottom: 1.5px solid var(--H3Dark1) !important;*/
    /*width: 36%;*/
    flex: 3.03;
}

.stat_green {
    text-align: center;
    width: 50%;
    background: #72da233d;
    display: flex;
    place-items: center;
    justify-content: center;
}

.match-card.yellow-card {
    background-color: #ffcc05;
    color: #000;
}

.match-card.red-card {
    background-color: #CA2027;
    color: white;
    margin: 0 3px;
}

.no-info-stats-row {
    display: flex;
    justify-content: center;
    height: 100%;
    place-items: center;
}

.card-container {
    display: flex;
}

.match-card {
    display: flex;
    place-items: center;
    justify-content: center;
    padding: 0 2px;
    border-radius: 2px;
    width: 8px;
    height: 16px;
    font-size: 11px;
    font-weight: 700;
}

.live-container {
    border-radius: 9px;
    cursor: pointer;
}

.not-live-icon {
    background-color: #1a3150;
    border-radius: 10px;
    padding: 2px;
    width: 15px;
}

.live-text {
    color: white;
    font-size: 13px;
    font-weight: 500;
    margin-left: 5px;
}

.abnd-match {
    font-weight: 600;
    color: var(--H3Red01);
}

.live-match-min {
    color: var(--H3Red01) !important;
    font-weight: 700;
}

.live-match-score {
    color: var(--H3Red01);
    font-weight: 700;
}

.versus-text {
    color: var(--H3Dark1);
    font-size: .8rem;
}

.live-match-count {
    z-index: 0;
    color: #fff;
    border-radius: 15px;
    font-weight: 600;
    font-size: 10px;
    padding: 3px 5px;
    line-height: 1;
    border: 2px solid #fff;
    background: red;
    position: absolute;
    right: -7px;
    top: -8px;
}

.no-live-matches-container {
    display: flex;
    justify-content: center;
    place-items: center;
}

.no-live-matches-text {
    font-size: 13px;
    font-weight: 700;
    color: #6f8caa;
}

.stats-week-days-section {
    display: flex;
    min-width: 22%;
    place-items: center;
    padding: 0 10px;
}

.searchInput {
    padding: 5px;
    border-radius: 0 5px 5px 0;
    background: #efefef;
    border: 1px solid #cacaca;
    display: inline-block;
    cursor: pointer;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.week-days-container {
    display: flex;
    flex: 1;
    justify-content: center;
}

.home-page-week-days-container {
    display: grid;
    place-items: center;
    justify-content: center;
}

.stats-half-time-scores-container {
    display: flex;
}

.stats-half-time-title {
    font-size: 11px;
    font-weight: 600;
}

.dates {
    font-size: 11px;
    font-weight: 700;
    color: var(--H3MidGrey1);
    cursor: pointer;
    display: flex;
    place-items: center;
    flex-direction: column;
    text-transform: uppercase;
    transition-delay: .1s;
    transition-duration: .2s;
}

.active-week-day {
    color: var(--H3Light0);
    font-weight: 700;
    font-size: 11px;
    display: flex;
    place-items: center;
    flex-direction: column;
    background: var(--H3Dark1);
    border-radius: 20px;
    padding: 5px 12px;
}

.stats-date-day-name {
    font-size: 10px;
}

.show-more-and-less-matches-container {

}

.show-less-matches-button {
    color: var(--H3Text2);
    font-size: 12px;
    cursor: pointer;
}

.show-less-matches-button:hover {
    color: var(--H3TeamFormD);
    transition-duration: .2s;
}

.show-more-matches-button {
    color: var(--H3Text2);
    font-size: 12px;
    cursor: pointer;
}

.show-more-matches-button:hover {
    color: var(--H3TeamFormD);
    transition-duration: .2s;
}

/*MOBILE VIEW*/
@media only screen and (max-width: 980px) {
    .stat-button {
        padding: .5rem 5px;
    }
}

@media only screen and (max-width: 768px) {
    .fixture-section {
        padding: 0;
        min-height: 500px;
    }

    .fixtures-general-container {
        width: 100%;
    }

    .fixture-table-row {
        height: 1.9rem;
    }

    .fixtures-top-header {
        display: none;
    }

    .matches-container {
        display: none;
    }

    .stats-header-red {
        display: none;
    }

    .stats-header-other {
        display: none;
    }

    .stats-header-odds {
        display: none;
    }

    .stats-matches-referee-name-td-container {
        display: none;
    }

    .matches-list-for-mobile {
        /*display: none;*/
    }

    .stats-container-for-web {
        display: none;
    }

    .stats-container-for-mobile {
        display: block;
        flex: 1;
    }

    .fav-matches-live-and-stats-container-for-mobile {
        display: flex;
        place-items: center;
        background: white;
        padding: 8px 0;
        border-bottom: 2px solid #e9e9e9;
    }

    .no-fav-matches-container {
        display: flex;
        justify-content: center;
    }

    .no-fav-matches-text {
        color: #5e6e84;
        font-size: 13px;
        font-weight: 600;
        margin-top: 10px;
    }

    .league-name-container-for-mobile {
        /*display: none;*/
    }

    .top-search-matches-container {
        margin: 10px;
    }

    .fixtures-section-mobile {
        display: flex;
    }

    .stats-top-header-mobile {
        display: flex;
        flex-direction: column;
        padding: .3rem;
        background: var(--H3Light0);
    }

    .match-info-container {
        /*grid-template-columns: 35px 40px auto minmax(70px, 110px) 40px minmax(70px, 110px) auto;*/
        grid-template-columns: 35px 30px minmax(100px, 160px) minmax(40px, auto) minmax(100px, 160px) 30px 35px;

    }

    .match-team-names {
        font-size: 12px;
    }

    .live-status-container {
        margin-left: .3rem;
    }

    .live-and-weekdays-container-mobile {
        display: flex;
        place-items: center;

    }

    .stats-mobile-button-container {
        display: flex;
        border: none;
        padding: 3px;
        margin: 5px 0;
    }

    .league-name-container {
        background: var(--H3Light1);
        color: var(--H3Dark1);
        display: flex;
        place-items: center;
    }

    .stat-button {
        flex: 1;
        text-align: center;
        padding: .5rem 0;
        cursor: pointer;
        font-size: 12px;
        font-weight: 500;
        border-left: none;
    }

    .stats-header-container {
        display: none;
    }

    .stat_yellow {
        flex: 1;
        border-left: 1px solid #e2e3e8;
        min-height: 2rem;
        display: grid;
        place-items: center;
    }

    .stat_red {
        flex: 1;
        border-left: 1px solid #e2e3e8;
        min-height: 2rem;
        display: grid;
        place-items: center;
    }
    .stat_green {
        flex: 1;
        border-left: 1px solid #e2e3e8;
        min-height: 2rem;
        display: grid;
        place-items: center;
    }

    .stats-type-mobile {
        display: flex;
        color: var(--text-color00);
    }

    .stats-value-mobile, .stats-type-mobile {
        display: flex;
        justify-content: center;
        font-size: 11px;
        margin-bottom: 3px;
    }

    .fixture-stat-style {
        color: #00b1ff;
        font-size: .7rem;
    }

    .stat-style-width-red {
        width: 100%;
    }

    .stat-style-width-other {
        width: 100%;
        display: flex;
        text-align: center;
        margin: 0 1px;
    }

    .no-info-stats {
        width: 100%;
    }

    .dates {
        font-size: 11px;
        font-weight: 700;
    }

    .active-week-day {
        color: white;
        font-weight: 700;
        font-size: 11px;
        background: #0c0c0c;
        border-radius: 20px;
        padding: 3px 15px;
        transition-delay: .1s;
        transition-duration: .2s;
    }
    .area-info-container {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 370px;
    }

    .stats-half-time-scores-container {
        display: none;
    }

    .league-name-and-flag-container {
        display: flex;
        /*grid-template-columns: auto 100px;*/
    }

    .league-name-and-flag-container-for-show-more-matches {
        display: flex;
        /*grid-template-columns: auto 100px;*/
    }

    .week-days-section {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 5px 0;
    }
}

@media only screen and (max-width: 500px) {
    .fixtures-general-container {
        width: auto;
    }
}

@media only screen and (max-width: 426px) {
    .match-info-container {
        grid-template-columns: 35px 30px minmax(70px, 130px) minmax(40px, auto) minmax(70px, 130px) 30px 35px;
    }

    .fixtures-general-container {
        width: auto;
    }

    .area-info-container {
        width: 270px;
    }

    .show-less-matches-button {
        font-size: 11px;
        text-align: center;
    }

    .show-more-matches-button {
        font-size: 11px;
        text-align: center;
    }
}

@media only screen and (max-width: 376px) {
    .area-info-container {
        width: 260px;
    }
}
