.lineupsGeneralSection {}
.lineupsTitleTextContainer {
    background-color: #efefef;
    padding: 10px;
}

.normal-row {
    background: #fff;
}

.stripped-row {
    background: #efefef;
}

.lineupsTitleText {
    font-size: 13px;
    color: #000000;
    font-weight: 700;
    font-family: Arial, Helvetica, sans-serif;
}
.lineupsAndBenchTableContainer {
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-right: 1px solid #efefef;
}

.lineupsGeneralCardContainer {
    flex: 1;
    margin: 5px;
    border-radius: 5px 5px 0 0;
    background-color: #ffffff;
    max-width: 310px;
    border: 1px solid #efefef;
    border-bottom: none;
}

.teamLogoAndName {
    display: flex;
    align-items: center;
    background-color: #e1e7ed;
    border-radius: 5px 5px 0 0;
    padding: 5px;
    cursor: pointer;
}
.lineupTeamName {
    padding-left: 5px;
    color: #000000;
    font-weight: 500;
    font-size: 12px;
}
.lineupPlayerNameGeneralContainer {
}
.lineupPlayerNameContainer {
    display: flex;
    align-items: center;
    padding: 3px 0;
    border-bottom: 1px solid #c2d1dc;
}
.lineupPlayerNumberAndName {
    color: #000000;
    flex: 1;
    text-align: center;
    font-size: 13px;
    padding: 0 5px;
}
.lineupPlayerName {
    color: #000000;
    flex: 10;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media only screen and (max-width: 1024px) {
    .lineupsGeneralCardContainer {
        max-width: 270px;
    }
}

@media only screen and (max-width: 768px) {
    .lineupsGeneralCardContainer {
        max-width: 240px;
    }

    .lineupPlayerNameContainer {
        padding: 7px;
        border-bottom: none;
    }

    .stripped-row {
        background: #fff;
    }

    .lineupsGeneralCardContainer {
        border-bottom: 1px solid #efefef;
    }

    .lineupPlayerName {
        color: var(--H3MidGrey1);
        font-size: 11px;
    }

    .lineupPlayerNumberAndName {
        color: var(--H3MidGrey1);
        font-size: 12px;
    }
}

@media only screen and (max-width: 480px) {
    .lineupsGeneralCardContainer {
        max-width: 210px;
    }
}

@media only screen and (max-width: 426px) {
    .lineupsGeneralCardContainer {
        max-width: 180px;
    }
}

@media only screen and (max-width: 376px) {
    .lineupsGeneralCardContainer {
        max-width: 170px;
    }
}
