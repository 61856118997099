.heading {
    margin-top: 30px;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
}

.sub-heading {
    font-size: 15px;
    font-weight: 600;
}

.sub-heading-icon {
    margin-right: 10px;
    margin-top: -2px;
}

.content-text {
    padding-left: 30px;
    color: #656565;
}

.terms-section ol, .terms-section ul {
    margin-left: 40px;
}

li {
    color: #656565;
    margin-bottom: 10px;
}
