.app-platform-direct-section {
    background: #fff;
    display: flex;
    justify-content: center;
    padding: 30px;
    width: 100%;
    margin: 10px 0;
    border-radius: 5px;
}

.app-direct-text {
    color: var(--H3Text1);
}
