.androidPlus .promo {
    background-color: #fff;
    display: block;
}

.androidPlus .promoPart--2 {
    display: none;
}

.androidPlus .promoBackground--original {
    background: #369223 no-repeat bottom / auto 100%;
    background-image: url('../../assets/icons/androidPageHeaderBackground.jpg');
}
.androidPlus .promoBackground {
    margin-top: -1px;
}

.androidPlus .promoWrapper {
    margin: 0 auto;
    padding: 0 20px;
    width: 960px;
}

.androidPlus .promoHeader {
    padding: 21px 0 98px;
}

.androidPlus .promoLogos {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}

.androidPlus .promoAndroidMainText {
    color: #fff;
    font-size: 54px;
    font-weight: 300;
    line-height: 1.41;
    margin: 94px auto 0;
    text-align: center;
    width: 750px;
}

.androidPlus .promoAndroidSecondText {
    color: #fff;
    font-size: 18px;
    font-weight: 300;
    line-height: 1.33;
    margin: 22px auto 0;
    text-align: center;
    width: 850px;
}

.androidPlus .promoPart {
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    box-sizing: border-box;
    padding: 44px 0 70px;
}

.androidPlus .promoChapter {
    align-items: flex-start;
    display: flex;
    flex-flow: row nowrap;
    font-size: 30px;
    font-weight: 300;
    justify-content: center;
    line-height: 1.2;
    margin: 0 0 50px;
}

.androidPlus .promoChapter__number {
    color: #b0b0b0;
    margin-right: 19px;
}

.androidPlus .promoChapter__text {
    color: #000;
}

.androidPlus .promoSettings {
    margin: 0 70px;
}

.androidPlus .promoSettings__icons {
    align-items: flex-start;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}

.androidPlus .promoSettings__iconBlock {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.androidPlus .promoSettings__rectangle--small {
    height: 136px;
    width: 153px;
}
.androidPlus .promoSettings__rectangle {
    align-items: center;
    background-color: #ececec;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    transform: skew(-3deg);
}

.androidPlus .promoSettings__rectangleIcon--settings {
    background: url('../../assets/icons/android-settings.png') no-repeat 50% / 58px 60px;
    height: 60px;
    width: 58px;
}
.androidPlus .promoSettings__rectangleIcon {
    transform: skew(3deg);
}

.androidPlus .promoSettings__rectangleIcon--security {
    background: url('../../assets/icons/android-security.png') no-repeat 50% / 52px 68px;
    height: 68px;
    width: 52px;
}

.androidPlus .promoSettings__rectangleIcon--switch {
    background: url('../../assets/icons/android-switch.png') no-repeat 50% / 78px 39px;
    height: 39px;
    width: 78px;
}

.androidPlus .promoSettings__iconText--1 {
    margin-top: 22px;
    width: 200px;
}

.androidPlus .promoSettings__iconText--2 {
    margin-top: 14px;
    width: 368px;
}

.androidPlus .promoSettings__iconText {
    color: #656565;
    line-height: 1.33;
    text-align: center;
}

.androidPlus .promoChapter {
    align-items: flex-start;
    display: flex;
    flex-flow: row nowrap;
    font-size: 30px;
    font-weight: 300;
    justify-content: center;
    line-height: 1.2;
    margin: 0 0 50px;
}

.androidPlus .promoSettings__rectangle--big {
    height: 240px;
    width: 368px;
}

.androidPlus .promoSettings__betweenText {
    color: #656565;
    font-size: 24px;
    margin-top: 100px;
}

.androidPlus .promoSettings__rectangleText {
    font-size: 22px;
    font-weight: 700;
    line-height: 1;
    transform: skew(3deg);
    width: 280px;
    word-wrap: break-word;
    text-align: center;
}

.androidPlus .promoSettings__rectangleText a {
    color: #1b7700;
}
.androidPlus .promo a {
    text-decoration: none;
}

.androidPlus .promoSettings__QR {
    background: no-repeat 50% / 200px 200px;
    height: 200px;
    transform: skew(3deg);
    width: 200px;
}

.androidPlus .promoInformation, .androidPlus .promoInformation__block {
    align-items: flex-start;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
}
.androidPlus .promoInformation {
    margin: 0 70px;
}

.androidPlus .promoInformation__block {
    border: 1px solid #e5e5e5;
    border-radius: 20px;
    margin-top: 42px;
    overflow: hidden;
    transform: skew(-3deg);
    width: 820px;
}

.androidPlus .promoInformation__mobile {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 188px 358px;
    height: 358px;
    margin: -156px 0 0 90px;
    transform: skew(3deg);
    width: 188px;
}

.androidPlus .promoInformation__text {
    color: #656565;
    font-size: 18px;
    line-height: 1.33;
    margin: 86px 0 82px 100px;
    transform: skew(3deg);
    width: 400px;
}

.androidPlus .promoSettings--2 {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
}

.androidPlus .promoSettings__iconBlock--2 {
    margin-right: 103px;
    width: 340px;
}

.androidPlus .promoSettings__rectangleIcon--download {
    background: url('../../assets/icons/android-download.png') no-repeat 50% / 53px 64px;
    height: 64px;
    width: 53px;
}

.androidPlus .promoInformation__text--fill2 {
    max-width: 340px;
}

.androidPlus .promoSettings__text {
    color: #656565;
    line-height: 1.33;
    margin-top: 22px;
    text-align: center;
}

.androidPlus .promoSettings__phones {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
}

.androidPlus .promoSettings__mobile--1, .androidPlus .promoSettings__mobile--2 {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 183px 360px;
}

.androidPlus .promoSettings__mobile {
    height: 360px;
    width: 183px;
}

@media only screen and (max-width: 1047px) {
    .androidPlus .promoSettings {
        margin: 0 40px;
    }

    .androidPlus .promoSettings__rectangleText {
        width: 200px;
    }

    .androidPlus .promoChapter__text {
        max-width: 400px;
    }

    .androidPlus .promoInformation__text--fill2 {
        max-width: 300px;
    }

    .androidPlus .promoSettings__phones {
        align-items: center;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
    }

    .androidPlus .promoSettings__mobile--1, .androidPlus .promoSettings__mobile--2 {
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: 132px 260px;
    }

    .androidPlus .promoSettings__rectangle--big {
        height: 250px;
        width: 100%;
    }

    .androidPlus .promoWrapper {
        padding: 0 16px;
        width: calc(100% - 32px);
    }

    .androidPlus .promoAndroidMainText, .androidPlus .promoAndroidSecondText {
        width: 600px;
    }

    .androidPlus .promoSettings__iconBlock--bigger {
        margin: 0 20px;
    }

    .androidPlus .promoSettings__iconBlock {
        width: 50%;
    }

    .androidPlus .promoSettings__icons {
        justify-content: space-around;
    }

    .androidPlus .promoSettings__iconText--2 {
        margin-top: 14px;
        width: 230px;
    }
}

@media only screen and (max-width: 700px) {
    .androidPlus .promoHeader {
        padding: 18px 0 57px;
    }

    .androidPlus .promoAndroidMainText {
        font-size: 28px;
        line-height: 1.29;
        margin: 47px auto 0;
        text-align: center;
        width: calc(100% - 80px);
    }

    .androidPlus .promoAndroidSecondText {
        font-size: 16px;
        line-height: 1.5;
        margin: 30px auto 0;
        text-align: center;
        width: calc(100% - 80px);
    }

    .androidPlus .promoPart {
        padding: 44px 0 0;
    }

    .androidPlus .promoWrapper {
        padding: 0;
        width: 100%;
    }

    .androidPlus .promoChapter {
        font-size: 24px;
        line-height: 1.5;
        margin: 0 auto 50px;
        width: calc(100% - 80px);
    }

    .androidPlus .promoSettings__icons {
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
    }

    .androidPlus .promoSettings__iconBlock--1 {
        margin-bottom: 53px;
    }

    .androidPlus .promoSettings__rectangle--small {
        height: 136px;
        margin-right: 22px;
        width: 153px;
    }

    .androidPlus .promoSettings__rectangleIcon--settings {
        background: url('../../assets/icons/android-settings.png') no-repeat 50% / 58px 60px;
        height: 60px;
        width: 60px;
    }

    .androidPlus .promoSettings__rectangleIcon--security {
        background: url('../../assets/icons/android-security.png') no-repeat 50% / 52px 68px;
        height: 70px;
        width: 60px;
    }

    .androidPlus .promoSettings__rectangleIcon--switch {
        background: url('../../assets/icons/android-switch.png') no-repeat 50% / 78px 39px;
        height: 60px;
        width: 80px;
    }

    .androidPlus .promoPart--2 {
        display: block;
    }

    .androidPlus .promoSettings__download {
        background-color: #3a9a26;
        border-radius: 8px;
        box-shadow: 2px 4px 12px 0 rgba(0, 0, 0, .16);
        margin: -28px auto 28px;
        text-align: center;
        width: 280px;
    }

    .androidPlus .promoSettings__download a {
        align-items: center;
        color: #fff;
        display: flex;
        font-size: 18px;
        font-weight: 500;
        justify-content: center;
        padding: 19px 0;
        text-decoration: none;
    }

    .androidPlus .promoPart--1 {
        display: none;
    }

    .androidPlus .promoSettings__downloadImage {
        border: 1px solid #e5e5e5;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 auto 55px;
        overflow: hidden;
        width: 280px;
    }

    .androidPlus .promoSettings__downloadMobile {
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: 162px 326px;
        height: 326px;
        margin: -144px auto 30px;
        width: 162px;
    }

    .androidPlus .promoSettings__downloadText {
        color: #656565;
        font-size: 18px;
        line-height: 1.33;
        margin: 0 auto 18px;
        text-align: center;
        width: 240px;
    }

    .androidPlus .promoSettings--2 {
        flex-direction: column;
        justify-content: center;
    }

    .androidPlus .promoSettings__iconBlock--2 {
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin-right: 0;
        width: 280px;
    }

    .androidPlus .promoSettings__rectangleIcon--download {
        background: url('../../assets/icons/android-download.png') no-repeat 50% / 53px 64px;
        height: 65px;
        width: 55px;
    }
}

@media only screen and (max-width: 450px) {
    .androidPlus .promoHeader {
        padding: 18px 0 56px;
    }

    .androidPlus .promoLogos__logo {
        height: 20px;
        margin-left: 16px;
        width: 160px;
    }

    .androidPlus .promoWrapper {
        padding: 0;
        width: 100%;
    }

    .androidPlus .promoAndroidMainText {
        font-size: 28px;
        line-height: 1.29;
        margin: 47px auto 0;
        text-align: center;
        width: calc(100% - 80px);
    }

    .androidPlus .promoAndroidSecondText {
        font-size: 16px;
        line-height: 1.5;
        margin: 30px auto 0;
        text-align: center;
        width: calc(100% - 80px);
    }

    .androidPlus .promoPart {
        padding: 44px 0 0;
    }

    .androidPlus .promoWrapper {
        padding: 0;
        width: 100%;
    }

    .androidPlus .promoChapter {
        font-size: 24px;
        line-height: 1.5;
        margin: 0 auto 50px;
        width: calc(100% - 80px);
    }

    .androidPlus .promoSettings__icons {
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
    }

    .androidPlus .promoSettings__iconBlock--1 {
        margin-bottom: 53px;
    }

    .androidPlus .promoSettings__rectangle--small {
        height: 98px;
        margin-right: unset;
        width: 110px;
    }

    .androidPlus .promoSettings__rectangleIcon--settings {
        background: url('../../assets/icons/android-settings.png') no-repeat 50% / 58px 60px;
        height: 60px;
        width: 60px;
    }

    .androidPlus .promoSettings__rectangleIcon--security {
        background: url('../../assets/icons/android-security.png') no-repeat 50% / 52px 68px;
        height: 70px;
        width: 60px;
    }

    .androidPlus .promoSettings__rectangleIcon--switch {
        background: url('../../assets/icons/android-switch.png') no-repeat 50% / 78px 39px;
        height: 60px;
        width: 80px;
    }

    .androidPlus .promoSettings__arrow {
        background: url('../../assets/icons/android-arrow-down.png') no-repeat 50% / 30px 18px;
        height: 18px;
        margin-bottom: 47px;
        margin-top: 0;
        width: 30px;
    }

    .androidPlus .promoPart--2 {
        display: block;
    }

    .androidPlus .promoSettings__download {
        background-color: #3a9a26;
        border-radius: 8px;
        box-shadow: 2px 4px 12px 0 rgba(0, 0, 0, .16);
        margin: -28px auto 28px;
        text-align: center;
        width: 280px;
    }

    .androidPlus .promoSettings__download a {
        align-items: center;
        color: #fff;
        display: flex;
        font-size: 18px;
        font-weight: 500;
        justify-content: center;
        padding: 19px 0;
        text-decoration: none;
    }

    .androidPlus .promoPart--1 {
        display: none;
    }

    .androidPlus .promoSettings__downloadImage {
        border: 1px solid #e5e5e5;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 auto 55px;
        overflow: hidden;
        width: 280px;
    }

    .androidPlus .promoSettings__downloadMobile {
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: 162px 326px;
        height: 326px;
        margin: -144px auto 30px;
        width: 162px;
    }

    .androidPlus .promoSettings__downloadText {
        color: #656565;
        font-size: 18px;
        line-height: 1.33;
        margin: 0 auto 18px;
        text-align: center;
        width: 240px;
    }

    .androidPlus .promoSettings--2 {
        flex-direction: column;
        justify-content: center;
    }

    .androidPlus .promoSettings__iconBlock--2 {
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin-right: 0;
        width: 280px;
    }

    .androidPlus .promoSettings__rectangleIcon--download {
        background: url('../../assets/icons/android-download.png') no-repeat 50% / 53px 64px;
        height: 65px;
        width: 55px;
    }

    .androidPlus .promoSettings__mobile--1, .androidPlus .promoSettings__mobile--2 {
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: 132px 260px;
    }
}










